<template>
  <a-modal
    title="重置密码"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">
    <a-spin :spinning="formLoading">
      <a-form :form="form">
        <a-form-item
          label="密码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-input placeholder="请输入密码" type="password" v-decorator="['newPassword', {rules: [{required: true, message: '请输入密码！'}]}]"/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { restPassword } from '@/api/user-rights/usermanage'

export default {
  name: 'AcRoleAuthorize',
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      // form申请单
      form: this.$form.createForm(this),
      // 自定义数据范围
      customRange: [],
      // 自定义选中的数据
      formLoading: false,
      loading: false,
      userId: null
    }
  },
  methods: {
    edit (record) {
      console.log('record', record)
      this.userId = record.userId
      this.visible = true
    },
    handleCancel () {
      this.form.resetFields()
      this.customRange = []
      this.visible = false
    },
    // 重置密码POST
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          restPassword(Object.assign(values, { userId: this.userId })).then((res) => {
            this.confirmLoading = false
            if (res.code === 200) {
              this.$message.success('操作成功')
              this.handleCancel()
              this.$emit('ok', values)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>

<style scoped>
.tree{
  background: #f5f5f557
}
</style>
